import articleImage from '../assets/pictures/article-picture-3.jpg'
import articleImage2 from "../assets/pictures/article-picture-4.jpeg";


export const blogData = [
  {
    id: 1,
    title: "How to use FTP to Upload files to your Cpanel using Filezilla",
    description: "Using FileZilla FTP Client to deploy to your Cpanel",
    date: "May 16, 2022",
    image: "https://miro.medium.com/max/828/1*zBkZKcmYkU8eKa_2PWYdrg.png",
    url: "https://medium.com/@faithfulolaleru09/how-to-use-ftp-to-upload-files-to-you-cpanel-using-filezilla-497ab28b27ed",
  },
  {
    id: 2,
    title: "Build a CRUD Service with Node.js, Express.js and MongoDB",
    description:
      "Start to finish creating a simple CRUD app with Javascript and NoSql Database",
    date: "May 17, 2022",
    image: "https://miro.medium.com/max/828/0*Bxzxxq666Cc8fxX5",
    url: "https://medium.com/@faithfulolaleru09/build-a-crud-service-with-node-js-express-js-and-mongodb-f03339f0af06#7fd3-74511616ce49",
  },
  {
    id: 3,
    title:
      "Reverse Engineering a Chatbot Project: Exploring the Integration of ChatGPT,...",
    description:
      "Building a Chatbot using Nextjs while integrating with LangChain.js, ChatGPT and Pinecone",
    date: "April 25, 2023",
    image: articleImage,
    url: "https://dev.to/ionknowmyname/reverse-engineering-a-chatbot-project-exploring-the-integration-of-chatgpt-langchainjs-and-pinecone-24h3",
  },
  {
    id: 4,
    title: "Deploy React App & Nestjs App to AWS EC2 Instance",
    description:
      "Step-by-step guide on how to deploy a React & Node app on AWS using Github Actions CI/CD",
    date: "Auguest 5, 2024",
    image: articleImage2,
    url: "https://dev.to/ionknowmyname/deploy-react-app-nestjs-app-to-aws-ec2-instance-554e",
  },
];




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/