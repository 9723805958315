import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'

import chatAppImage from '../assets/pictures/chatapp.png'
import laravelprojectImage from '../assets/pictures/laravelproject.png'
import memotrackerImage from '../assets/pictures/memotracker.png'
import onlineexamImage from '../assets/pictures/onlineexam.png'
import statecapitalImage from '../assets/pictures/statecapital2.png'
import tasktrackerImage from '../assets/pictures/tasktracker.png'
import sabiImage from '../assets/pictures/sabi.png'



export const projectsData = [
    // {
    //     id: 1,
    //     projectName: 'Sabi.am PWA',
    //     projectDesc: 'B2B/B2C e-Commerce Application catering for over 230,000 users in the ecosystem',
    //     tags: ['Java', 'SpringBoot', 'Postgres', 'MongoDb'],
    //     code: 'https://faithfulolaleru.com',
    //     demo: 'https://spaceso2o.com/',
    //     image: sabiImage
    // },
    {
        id: 2,
        projectName: 'Chat App with WebSockets',
        projectDesc: 'This project seeks to replicate a chat room where multiple users can chat in realtime. Uses Web Sockets API and SocksJs to make this happen',
        tags: ['Java', 'SpringBoot', 'WebSockets', 'Javascript'],
        code: 'https://github.com/ionknowmyname/chatAppWithWebSockets',
        demo: 'https://chat-app-websockets-nodejs.onrender.com',
        image: chatAppImage
    },
    {
        id: 3,
        projectName: 'Internal Memo Manager App',
        projectDesc: 'This project was created for large organizations that are going green i.e reducing papertrail. The App tracks Internal Memos, and activities performed on them',
        tags: ['React', 'Material UI', 'Express', "MongoDB","NodeJS"],
        code: 'https://github.com/ionknowmyname/myMemoManagerApp',
        demo: 'https://faithfulolaleru.com',
        image: memotrackerImage
    },
    {
        id: 4,
        projectName: 'Client Relation Management System',
        projectDesc: ' A CRM app for adding and managing clients and their subscriptions',
        tags: ['PHP', 'Laravel', 'Vue.Js', 'SQL'],
        code: 'https://github.com/ionknowmyname/forInterview.laravel2',
        demo: 'https://faithfulolaleru.com',
        image: laravelprojectImage
    },
    {
        id: 5,
        projectName: 'TaskTracker/To-Do List',
        projectDesc: 'A simple To-do list with adding tasks with day & time features, setting reminders, listing available tasks, and setting tasks to done.',
        tags: ['React', 'Javascript'],
        code: 'https://github.com/ionknowmyname/REACT-task-tracker-new',
        demo: 'https://react-task-tracker-new.vercel.app/',
        image: tasktrackerImage
    },
    {
        id: 6,
        projectName: 'Computer Based Test App',
        projectDesc: 'Web App to Simulate a Computer Based Test. Consists of some subjects and a countdown timer for each different course, and results at the end.',
        tags: ['PHP', 'AJAX', 'JavaScript', 'MySQL'],
        code: 'https://github.com/ionknowmyname/forInterview.laravel2',
        demo: 'https://faithfulolaleru.com',
        image: onlineexamImage
    },
    {
        id: 7,
        projectName: 'State-Capital Search App',
        projectDesc: 'Simple Vanilla Js app that searches for American states and their capitals',
        tags: ['Javascript', 'HTML', 'CSS'],
        code: 'https://github.com/ionknowmyname/forInterview.laravel2',
        demo: 'https://faithfulolaleru.com',
        image: statecapitalImage
    }
    
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/