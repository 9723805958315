export const educationData = [
  {
    id: 1,
    institution: "ALX Africa",
    course: "ALX Cloud Computing Programe",
    startYear: "2023",
    endYear: "2024",
  },
  {
    id: 2,
    institution: "Udacity NanoDegree",
    course: "Datascience Programming with Python",
    startYear: "2021",
    endYear: "2021",
  },

  // {
  //     id: 2,
  //     institution: 'University of Texas at Arlington',
  //     course: 'B.S. Mechanical Engineering',
  //     startYear: '2015',
  //     endYear: '2018 (75 hours completed)'
  // },
  {
    id: 3,
    institution: "University of Lagos, Nigeria",
    course: "B.S. Systems Engineering",
    startYear: "2014",
    endYear: "2015 (30 hours completed)",
  },
];