export const socialsData = {
    github: 'https://github.com/ionknowmyname',
    facebook: '',
    linkedIn: 'https://www.linkedin.com/in/faithfulolaleru/',
    instagram: '',
    codepen: '',
    twitter: 'https://twitter.com/general_blacque',
    reddit: '',
    blogger: '',
    medium: 'https://medium.com/@faithfulolaleru09/',
    stackOverflow: '',
    gitlab: '',
    youtube: ''
}